import React, { useEffect, useState, useRef } from "react";
import {
  substract_icon,
  add_icon,
  check,
  close_icon,
} from "../../.././src/assets";
import { useTranslation } from "react-i18next";

const LooseQuantityModal = ({
  initialQuantity,
  onConfirm,
  onCancel,
  product,
}) => {
  const [tempQuantity, setTempQuantity] = useState(initialQuantity);
  const inputRef = useRef(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const fetchWeight = async () => {
    // console.log("CHLAAA");
    // onConfirm(10)

    // setTempQuantity(10);
    try {
      const port = await navigator.serial.requestPort();
      await port.open({ baudRate: 9600 });

      const textDecoder = new TextDecoderStream();
      const readableStreamClosed = port.readable.pipeTo(textDecoder.writable);
      const reader = textDecoder.readable.getReader();

      // Read the data from the serial port
      const { value, done } = await reader.read();
      if (done) {
        // console.log("VALUE", value);
        reader.releaseLock();
        return;
      }

      // console.log("Weight data received:", value);

      const weightMatch = value.match(/(\d+)\s*g/);
      if (weightMatch) {
        const weightInGrams = parseFloat(weightMatch[1]);
        // console.log("Weight in grams:", weightInGrams);
        // setLatestWeight(weightInGrams);
        setTempQuantity(weightInGrams);
        // onConfirm(weightInGrams);
        onConfirm(weightInGrams, product.prod_id);
      } else {
        console.error("Weight data format is incorrect");
      }

      // console.log("TEMP Quantity:", tempQuantity);

      await reader.cancel();
      await readableStreamClosed.catch(() => {});
      await port.close();
      // console.log("Serial port closed");
    } catch (error) {
      console.error("Error accessing serial port:", error.message);
    }
  };

  const handleInput = (e) => {
    const value = e.target.value;
    // let newValue = value.replace(/[^\d.]/g, "").replace(/\.(.*)\./g, ".$1");
    let newValue = value.replace("ز", "."); // replace ز with .
    let decimalCount = (newValue.match(/\./g) || []).length;
    if (decimalCount > 1) {
      newValue = newValue.replace(/\.(.*)\./g, ".$1"); // remove additional decimal points
    }
    if (newValue.includes(".")) {
      let decimalIndex = newValue.indexOf(".");
      let integerPart = newValue.substring(0, decimalIndex);
      let fractionalPart = newValue.substring(decimalIndex);
      fractionalPart = fractionalPart.replace(/[^\d]/g, ""); // remove non-numeric characters
      if (fractionalPart.length > 3) {
        fractionalPart = fractionalPart.substring(0, 3); // limit to 3 digits after decimal
      }
      newValue = integerPart + "." + fractionalPart;
    } else {
      newValue = newValue.replace(/[^\d]/g, ""); // allow only digits
      if (newValue.length > 4) {
        newValue = newValue.substring(0, 4); // limit to 4 digits without decimal
      }
    }
    e.target.value = newValue;

    setTempQuantity(newValue);
    if (newValue === "") {
      setTempQuantity(0);
    } else {
      const floatValue = parseFloat(newValue);
      if (floatValue < 10000) {
        setTempQuantity(floatValue);
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      console.log("MMMM");
      onConfirm(tempQuantity);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      onCancel();
    }
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 backdrop-blur-md drop-shadow-sm">
      <div className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-40"></div>
      <section
        className="relative flex flex-col px-12 py-12 text-lg font-semibold leading-6 bg-white rounded-[25px] text-zinc-800 max-md:px-5 z-50"
        style={{ width: "500px" }}
      >
        <header className="flex gap-5 text-2xl max-md:flex-wrap max-md:max-w-full">
          <h1 className="flex-auto my-auto">{t("Enter Quantity")}</h1>
          <button
            type="button"
            onClick={onCancel}
            className="absolute top-4 right-3 text-zinc-800"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </header>
        <div
          className={`flex flex-row items-center ${
            i18n.language === "ar" ? "justify-end" : "justify-start"
          }`}
        >
          <div className="relative w-full">
            <input
              ref={inputRef}
              type="text"
              inputMode="numeric"
              maxLength={7}
              onChange={handleInput}
              onKeyPress={handleKeyPress}
              onKeyDown={handleKeyDown}
              className="p-3 mt-4 rounded-xl border border-1 border-neutral-400 border-opacity-50 text-black w-full focus:outline-none"
            />

            <span
              className={`absolute ${
                i18n.language === "ar" ? "left-3" : "right-3"
              } top-10 transform -translate-y-1/2 text-md text-black`}
            >
              {t("KG")}
            </span>
            {/* <span className="absolute right-3 top-7 transform -translate-y-1/1 text-md text-black">
              {t("KG")}
            </span> */}
          </div>

          {/* <span className="absolute right-8 top-1/2-translate-y-1/2 px-1 border-l text-md text-black max-md:pe-3">
                {t("KG")}
              </span> */}
          {/* <button
                onClick={() => {
                  fetchWeight();
                }}
                className="loose-popover"
              >
                <img
                  loading="lazy"
                  src={weight_icon}
                  alt=""
                  className="absolute right-0 top-7 h-8 w-8 object-contain"
                />
              </button> */}
        </div>
        <div className="flex justify-between gap-5">
          <button
            onClick={onCancel}
            className="justify-center items-center px-12 py-3.5 mt-10 text-xl text-center text-[#7EC242] rounded-lg border-2 border-[#7EC242] border-solid capitalize whitespace-nowrap bg-white rounded-lg max-md:px-5 max-md:mt-10 max-md:max-w-full"
          >
            {t("Cancel")}
          </button>
          <button
            onClick={() => onConfirm(tempQuantity)}
            className="justify-center items-center px-12 py-3.5 mt-10 text-xl text-center text-white capitalize whitespace-nowrap bg-[#7EC242] rounded-lg max-md:px-5 max-md:mt-10 max-md:max-w-full"
          >
            {t("Confirm")}
          </button>
        </div>
      </section>
    </div>
  );
};

export default LooseQuantityModal;
