export const URLS = {
  LOGIN_API_URL: "UserLogin",
  OPENING_CASH: "Pos/OpeiningCash",
  SEARCH_PRODUCT:"ProductSearch",
  OPEN_SESSION_INFO: "PosOpenSessionData",
  POS_CLOSE_SESSION: "PosCloseSession",
  GET_CUSTOMER_DETAILS:"GetCustomerdetails",
  CREATE_CUSTOMER: "CreateCustomers",
  UPDATE_CUSTOMER_DATA: "UpdateCustomerData",
  CREATE_ORDER_NEW: "CreatePosOrderNew",
  PAYMENT_METHODS: "PaymentMethod",
  POS_ORDERS: "/PosOrders",
  POS_ORDER_LINES: "PosOrderLines",
  RECEIPT_ORDER_DETAILS: "ReceiptOrderdetails",
  CASH_IN_OUT: "Pos/CashInOut",
  GET_CATEGORIES:"PosGetProductCategory",
  GET_CATEGORY_PROD:"PosGetCategoryProducts",
  GET_USER_DETAILS: "GetUserDetails",
  CHANGE_LANGUAGE: "/UserLanguage",
  SEND_CUSTOMER_SMS: "/SendCustomerSms",
  VERIFY_RETURN: "/CheckProductQuantityInOrderLines",
  REFUND_ORDER: "/PosRefundOrder",
  REFUND_ORDER_PAYMENT: "/PosRefundPayment",
  UPDATE_FAV_PROD : "ProductFavorite",
  GET_ADDRESS_TYPES: "/GetAddressType",
  COLLECT_PAYMENT: "/MakeOrderPayment",

};

// V2 Endpoints 

// export const URLS = {
//   LOGIN_API_URL: "V2/UserLogin",
//   OPENING_CASH: "/V2/Pos/OpeiningCash",
//   SEARCH_PRODUCT:"ProductSearch",
//   OPEN_SESSION_INFO: "V2/PosOpenSessionData",
//   POS_CLOSE_SESSION: "V2/PosCloseSession",
//   GET_CUSTOMER_DETAILS:"V2/GetCustomerdetails",
//   CREATE_CUSTOMER: "V2/CreateCustomers",
//   UPDATE_CUSTOMER_DATA: "V2/UpdateCustomerData",
//   CREATE_ORDER_NEW: "V2/CreatePosOrderNew",
//   PAYMENT_METHODS: "V2/PaymentMethod",
//   POS_ORDERS: "V2/PosOrders",
//   POS_ORDER_LINES: "V2/PosOrderLines",
//   RECEIPT_ORDER_DETAILS: "V2/ReceiptOrderdetails",
//   CASH_IN_OUT: "V2/Pos/CashInOut",
//   GET_CATEGORIES:"V2/PosGetProductCategory",
//   GET_CATEGORY_PROD:"V2/PosGetCategoryProducts",
//   GET_USER_DETAILS: "V2/GetUserDetails",
//   CHANGE_LANGUAGE: "V2/UserLanguage",
//   SEND_CUSTOMER_SMS: "V2/SendCustomerSms",
//   VERIFY_RETURN: "V2/CheckProductQuantityInOrderLines",
//   REFUND_ORDER: "V2/PosRefundOrder",
//   REFUND_ORDER_PAYMENT: "V2/PosRefundPayment",
//   UPDATE_FAV_PROD : "ProductFavorite",
//   GET_ADDRESS_TYPES: "GetAddressType",
//   COLLECT_PAYMENT: "V2/MakeOrderPayment",

// };

