// reducers/paymentReducer.js

import { SET_SELECTED_PAYMENT_METHOD, CLEAR_PAYMENT_METHOD } from "../Action/paymentAction";

const initialState = {
  selectedPaymentMethod: null,
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_PAYMENT_METHOD:
      return {
        ...state,
        selectedPaymentMethod: action.payload,
      };

    case CLEAR_PAYMENT_METHOD:
        return {
            ...state,
            selectedPaymentMethod: null
        };
    default:
      return state;
  
  }
};

export default paymentReducer;