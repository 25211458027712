import React, { useEffect, useRef, useState } from "react";
import ProductCart from "./ProductCart";
import { CustomerSearchBar } from "./CustomerSearchBar";
import { useTotalAmount } from "../TotalAmountContext";
import { useItemCount } from "../ItemCountContext";
import PaymentModal from "../../Modal/Payment_Modal";
import {
  cash_in_session,
  cash_out_session,
  clear_cart_icon,
  customers_icon,
  quick_settle,
  return_icon,
} from "../../../assets";
import AddCustomer_Modal from "../../Modal/AddCustomer_Modal";
import { useSelector } from "react-redux";
import axiosInstance from "../../../AxiosInstance";
import { URLS } from "../../../API/API endpoints";
import { useNavigate } from "react-router-dom"; // Updated import for navigation
import {
  addProducts,
  clearProducts,
} from "../../../Redux/Action/productsAction";
import { useDispatch } from "react-redux";
import {
  clearCustomer,
  setCustomer,
} from "../../../Redux/Action/getcustomerAction";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import CashIn_Modal from "../../Modal/CashIn_Modal";
import { clearReturnProducts } from "../../../Redux/Action/returnproductAction";
import { toast } from "react-toastify";
import { setForRefund } from "../../../Redux/Action/refundAction";
import SessionLogout from "../../Modal/SessionLogout_Modal";
import { clearPaymentMethod } from "../../../Redux/Action/paymentAction";
import SubscriptionExpired from "../../Modal/SubscriptionExpired";

const SESSION_STORAGE_KEY = "products";
const LoadingSpinner = () => {
  return (
    <div
      style={{ zIndex: 50 }}
      className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50"
    >
      <div className="spinner">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

function CustomerButton({ openAddCustomerModal, disabled }) {
  return (
    <div
      onClick={disabled ? undefined : openAddCustomerModal}
      // className={`flex flex-col justify-centerr w-[190px] items-center py-1.5 whitespace-nowrap rounded-xl shadow-sm bg-gradient-to-b from-yellow-200 via-yellow-400 to-yellow-400 text-white max-md:px-5 ${
      //   disabled ? "opacity-60 cursor-not-allowed" : "cursor-pointer"
      // }`}
      className={`flex flex-col justify-centerr w-[190px] items-center py-1.5 whitespace-nowrap rounded-xl shadow-sm bg-[#FABF01] text-white max-md:px-5 ${
        disabled ? "opacity-60 cursor-not-allowed" : "cursor-pointer"
      }`}
    >
      <img
        src={customers_icon}
        alt="customers_icon"
        className="self-center w-7 aspect-square"
      />
      <div>{t("Customers")}</div>
    </div>
    // <div
    //   className="flex flex-col justify-centerr w-[190px] items-center py-1.5 whitespace-nowrap rounded-xl shadow-sm bg-gradient-to-b from-yellow-200 via-yellow-400 to-yellow-400 text-white max-md:px-5 cursor-pointer"
    //   onClick={openAddCustomerModal}
    // >
    //   <img
    //     src={customers_icon}
    //     alt="customers_icon"
    //     className="self-center w-7 aspect-square"
    //   />
    //   <div>{t("Customers")}</div>
    // </div>
  );
}

// function ReturnButton() {
//   return (
//     <div className="flex flex-col justify-center px-12 py-1.5 whitespace-nowrap rounded-xl shadow-sm bg-gradient-to-b from-red-200 via-red-500 to-red-500 text-white max-md:px-5">
//       <img
//         src={return_icon}
//         alt="return_icon"
//         className="self-center w-7 aspect-square"
//       />
//       <div>Return</div>
//     </div>
//   );
// }

function CashInButton({ openCashInModal }) {
  return (
    <button
      onClick={openCashInModal}
      // className="flex flex-col justify-centerr w-[190px] items-center  py-1.5 rounded-xl shadow-sm bg-gradient-to-b from-lime-200 via-lime-500 to-lime-600 text-white max-md:px-5"
      className="flex flex-col justify-centerr w-[190px] items-center  py-1.5 rounded-xl shadow-sm bg-[#7EC242] text-white max-md:px-5"
    >
      <img
        src={cash_in_session}
        alt="cash_in_session"
        className="self-center w-7 aspect-square"
      />
      <div>{t("Cash IN")}</div>
    </button>
  );
}

function CashOutButton({ openCashOutModal }) {
  return (
    <div
      onClick={openCashOutModal}
      // className="flex flex-col justify-center w-[190px] cursor-pointer items-center py-1.5 rounded-xl shadow-sm bg-gradient-to-b from-blue-200 via-blue-500 to-blue-500 text-white max-md:px-5"
      className="flex flex-col justify-center w-[190px] cursor-pointer items-center py-1.5 rounded-xl shadow-sm bg-[#1b4597] text-white max-md:px-5"
    >
      <img
        src={cash_out_session}
        alt="cash_out_session"
        className="self-center w-7 aspect-square"
      />
      <div>{t("Cash OUT")}</div>
    </div>
  );
}

function QuickSettleButton({ onClick, totalQuantity }) {
  // console.log("TOTAL Q", totalQuantity);

  return (
    <div
      onClick={totalQuantity > 0 ? onClick : undefined}
      // className={`flex flex-col justify-center w-[190px] cursor-pointer whitespace-nowrap items-center py-1.5 rounded-xl shadow-sm bg-gradient-to-b from-blue-200 via-blue-500 to-blue-500 text-white max-md:px-5 ${
      //   totalQuantity <= 0 && "opacity-60 cursor-not-allowed"
      // }`}
      className={`flex flex-col justify-center w-[190px] cursor-pointer whitespace-nowrap items-center py-1.5 rounded-xl shadow-sm bg-[#1b4597] text-white max-md:px-5 ${
        totalQuantity <= 0 && "opacity-60 cursor-not-allowed"
      }`}
    >
      <img
        src={quick_settle}
        alt="quick_settle"
        className="self-center w-7 aspect-square"
      />
      <div>{t("Quick Checkout")}</div>
    </div>
  );
}

const ReturnButton = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setForRefund(true));
    navigate(`/orders?refundorder`);
    // navigate(`/orders?refundorder`);
  };
  return (
    <div
      onClick={handleClick}
      className="flex flex-col justify-center w-[190px] cursor-pointer whitespace-nowrap items-center py-1.5 rounded-xl shadow-sm  bg-[#F42020] text-white max-md:px-5"
      // className="flex flex-col justify-center w-[190px] cursor-pointer whitespace-nowrap items-center py-1.5 rounded-xl shadow-sm  bg-gradient-to-b from-red-200 via-red-500 to-red-500 text-white max-md:px-5"
    >
      <img
        src={return_icon}
        alt="return_icon"
        className="self-center w-7 aspect-square"
      />
      <div>{t("Return")}</div>
    </div>
  );
};

// const ReturnButton = () => {
//   const { t, i18n } = useTranslation();
//   const navigate = useNavigate();
//   const dispatch = useDispatch();

//   const handleClick = () => {
//     dispatch(setForRefund(true));
//     navigate(`/orders?refundorder`);
//     // navigate(`/orders?refundorder`);
//   };
//   return (
//     <div
//       onClick={handleClick}
//       className="flex justify-center w-[21vh] py-2 font-semibold bg-gradient-to-b from-red-400 via-red-500 to-red-500 text-white whitespace-nowrap rounded-lg cursor-pointer"
//     >
//       <img
//         src={return_icon}
//         alt="return_icon"
//         className="shrink-0 self-start w-6 aspect-[0.96]"
//       />
//       <div className="mt-0.5 ml-1">{t("Return")}</div>
//     </div>
//   );
// };

const SessionFooter = ({ setModals, localProducts, setSelectedIndex }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [partnerId, setPartnerId] = useState("");
  const { totalAmount } = useTotalAmount();
  const { itemCount } = useItemCount();
  const [paymentMethod, setPaymentMethod] = useState();
  const { user } = useSelector((state) => state?.login);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isAddCustomerModalOpen, setIsAddCustomerModalOpen] = useState(false);
  const [isAddCustomerModalOpen2, setIsAddCustomerModalOpen2] = useState(false);
  const [isSessionLogout, setIsSessionLogout] = useState(false);
  const [isSubscription, setIsSubscription] = useState(false);

  const [tenderCash, setTenderCash] = useState(0);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState();
  const Change = tenderCash - total;
  const navigate = useNavigate(); // Using useNavigate hook for navigation
  // console.log("tenderCash==>",tenderCash)
  const { selectedCustomer } = useSelector((state) => state.getcustomer);
  const [isCashInModalOpen, setIsCashInModalOpen] = useState(false);
  const [isCashOutModalOpen, setIsCashOutModalOpen] = useState(false);
  const returnProducts = localProducts.filter((product) => product.isReturn);
  const newProducts = localProducts.filter((product) => !product.isReturn);
  const [isOrderProcessing, setIsOrderProcessing] = useState(false);
  const [isApiInProgress, setIsApiInProgress] = useState(false);

  // const localProducts = useSelector((state) => state.products.products);
  // const returnProducts = useSelector((state) => state.returnProductReducer.products);

  // console.log("RETURN PRODUCTS------->", returnProducts);

  // console.log("localProducts-->", localProducts)
  const openCashInModal = () => setIsCashInModalOpen(true);
  const closeCashInModal = () => setIsCashInModalOpen(false);

  const openCashOutModal = () => setIsCashOutModalOpen(true);
  const closeCashOutModal = () => setIsCashOutModalOpen(false);

  const openPaymentModal = () => setIsPaymentModalOpen(true);
  const closePaymentModal = () => setIsPaymentModalOpen(false);
  const openAddCustomerModal = () => setIsAddCustomerModalOpen(true);
  const closeSubscriptionModal = () => setIsSubscription(false);


  const openSessionLogout = () => setIsSessionLogout(true);

  // const [dashboardData, setDashboardData] = useState({});
  const userData = useSelector((state) => state?.login?.user?.result);

  // useEffect(() => {
  //   getData();
  // }, []);

  // const getData = async () => {
  //   try {
  //     // setLoading(true)
  //     const response = await axiosInstance.post(URLS.OPEN_SESSION_INFO, {
  //       user_id: userData?.user_id,
  //       company_id: userData?.company_id,
  //     });
  //     if (response?.data?.result?.status === 200) {
  //       setDashboardData(response?.data?.result?.response[0]);
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   } finally {
  //     // setLoading(false)
  //   }
  // };

  const conformAddCustomerModal = (customer) => {
    if (customer?.partnerId) {
      dispatch(setCustomer(customer));
    }
    setIsAddCustomerModalOpen(false);
    setIsPaymentModalOpen(true);
  };

  const confirmAddCustomerModal = (customer, showSkipButton) => {
    dispatch(setCustomer(customer));
    setIsAddCustomerModalOpen2(false);
  };

  // const handlePayButtonClick = () => {
  //   if (!selectedCustomer?.partnerId) {
  //     openAddCustomerModal();
  //   } else {
  //     openPaymentModal();
  //   }
  // };

  const handlePayButtonClick = () => {
    if (returnProducts.length > 0) {
      openPaymentModal();
    } else if (!selectedCustomer?.partnerId) {
      openAddCustomerModal();
    } else {
      openPaymentModal();
    }
  };

  useEffect(() => {
    //  setModals(isPaymentModalOpen || isAddCustomerModalOpen ? true : false)
    setModals(isPaymentModalOpen || isAddCustomerModalOpen ? true : false);
  }, [isPaymentModalOpen, isAddCustomerModalOpen]);

  useEffect(() => {
    const total = localProducts?.reduce((sum, product) => {
      return sum + product.sale_price * product.quantity;
    }, 0);
    setTotal(total);
  }, [localProducts]);

  const getPaymentMethod = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.post(URLS.PAYMENT_METHODS, {
        user_id: user?.result?.user_id,
      });
      // console.log("getPaymentMethod--> ", response?.data?.result?.data);
      if (response?.data.result.data != null) {
        handleQuickSettle(response?.data?.result?.data[0]);
      }
    } catch (err) {
      console.error(err);
    } finally {
    }
  };

  const handleQuickSettle = async (paymentMode) => {
    if (returnProducts?.length > 0) {
      refundOrderValidate(true, paymentMode);
    } else {
      if (totalQuantity <= 0) {
        return;
      }
      try {
        setLoading(true);
        const lines = localProducts
          ?.filter((item) => item.quantity > 0)
          .map((item) => {
            return [
              0,
              0,
              {
                name: user?.result?.session_name,
                product_id: item.prod_id,
                full_product_name: item.name,
                qty: item.quantity,
                price_unit: item.sale_price,
                price_subtotal: item.sale_price * item.quantity,
                discount: 0,
                tax_ids: [[6, false, []]],
                price_subtotal_incl: item.sale_price * item.quantity,
              },
            ];
          });

        let partner = 0;
        let date = new Date();

        const padToTwoDigits = (num) => num.toString().padStart(2, "0");
        const year = date.getFullYear();
        const month = padToTwoDigits(date.getMonth() + 1);
        const day = padToTwoDigits(date.getDate());
        const hours = padToTwoDigits(date.getHours());
        const minutes = padToTwoDigits(date.getMinutes());

        let formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
        let payment_id;
        let totalAmount;

        const cashMethod = paymentMethod?.find((item) => item.name === "Cash");
        // console.log("payment-->", cashMethod);

        let payment_type;

        if (paymentMode?.name === "Cash") {
          payment_type = "cash";
        } else if (paymentMode?.name === "Bank") {
          payment_type = "bank";
        } else if (paymentMode?.name === "Delivery") {
          payment_type = "delivery";
        } else if (paymentMode?.name === "Cash & Bank") {
          payment_type = "cash_bank";
        } else {
          payment_type = ""; // Default case if none match
        }

        payment_id = paymentMode.id;

        totalAmount = total;
        let payloads = {
          data: {
            pos_session_id: user?.result?.session_id,
            user_id: user?.result?.user_id,
            state: "paid",
            payment_type: payment_type,
            amount_paid: total,
            amount_total: total,
            amount_tax: 0,
            amount_return: tenderCash ? tenderCash - total : 0,
            lines: lines,
            to_invoice: false,
            sequence_number: "",
            to_ship: false,
            is_tipped: false,
            tip_amount: 0,
            partner_id: partner && partner != "" ? partner : 0,
            creation_date: formattedDate,
            fiscal_position_id: "",
            pricelist_id: 1,
            statement_ids: [
              [
                0,
                0,
                {
                  name: formattedDate,
                  payment_method_id: JSON.parse(payment_id),
                  amount: totalAmount,
                  payment_status: "",
                  ticket: "",
                  card_type: "",
                  cardholder_name: "",
                  transaction_id: "",
                },
              ],
            ],
          },
        };
        const response = await axiosInstance.post(
          URLS.CREATE_ORDER_NEW,
          payloads
        );
        if (response?.data.result.status === "Successfully") {
          const order_id = response?.data?.result?.order_id;
          navigate(`/OrderCheckout?order_id=${order_id}`);
          dispatch(clearPaymentMethod());
        } else if (
          response?.data.result.status === "Session not in opened state."
        ) {
          setIsSessionLogout(true);
        }else if (response?.data.result.status_code === 400 && response?.data.result.status === "Subscription is Expired...Please Renew.") {
          setIsSubscription(true);
        }
      } catch (err) {
      } finally {
        setLoading(false);
        setIsOrderProcessing(false);
      }
    }
  };

  // const order_id = new URLSearchParams(window.location.search).get(
  //   "refundorder"
  // );
  const order_id = returnProducts?.[0]?.order_id;

  // console.log("NEWWWW PRODDDD", order_id);

  // const refundOrderPayload = {
  //   lang: i18n.language === "ar" ? "ar_001" : "en_US",
  //   order_id: parseInt(order_id),
  //   session_id: user?.result?.session_id,
  //   lines: returnProducts.map((product) => ({
  //     product_id: product?.product_id || product?.id,
  //     qty: product.quantity,
  //   })),
  //   new_lines: newProducts?.map((product) => ({
  //     product_id: product.prod_id,
  //     qty: product.quantity,
  //     price_unit: product.sale_price,
  //   })),
  // };

  const refundOrderValidate = async (isQuick, paymentMode) => {
    let payment_type;

    if (paymentMode?.name === "Cash") {
      payment_type = "cash";
    } else if (paymentMode?.name === "Bank") {
      payment_type = "bank";
    } else if (paymentMode?.name === "Delivery") {
      payment_type = "delivery";
    } else if (paymentMode?.name === "Cash & Bank") {
      payment_type = "cash_bank";
    } else {
      payment_type = ""; // Default case if none match
    }

    const refundOrderPayload = {
      lang: i18n.language === "ar" ? "ar_001" : "en_US",
      order_id: parseInt(order_id),
      payment_type: payment_type,
      session_id: user?.result?.session_id,
      lines: returnProducts.map((product) => ({
        product_id: product?.product_id || product?.id,
        qty: product.quantity,
      })),
      new_lines: newProducts?.map((product) => ({
        product_id: product.prod_id,
        qty: product.quantity,
        price_unit: product.sale_price,
      })),
    };

    setLoading(true);
    const response = await axiosInstance.post(
      URLS.REFUND_ORDER,
      refundOrderPayload
    );

    if (
      response?.data?.result?.error ===
      "Return and purchase product(s) cannot be the same"
    ) {
      toast.error(
        t(
          "Cannot return and repurchase the same item. Please adjust your order."
        )
      );
      setLoading(false);
      setIsApiInProgress(false);

      return;
    }
    if(response?.data?.result?.state === "Subscription is Expired...Please Renew."){
      setIsSubscription(true);
      setLoading(false);
      setIsApiInProgress(false);
      return;
    }

    if (response?.data.result) {
      const refundOrderId = response?.data?.result?.refund_order;
      let payment_id = paymentMode.id;

      const paymentPayload = {
        refund_order_id: refundOrderId,
        remark: "TEST",
        payments: [],
        amount_return: 0,
      };

      if (paymentMode.name === "Cash") {
        paymentPayload.payments.push({
          payment_id: payment_id, // Cash payment method id
          amount: totalAmount, // Amount for cash payment
        });
      } else if (paymentMode.name === "Delivery") {
        paymentPayload.payments.push({
          payment_id: payment_id, // Delivery payment method id
          amount: 0, // Amount will be ZERO
        });
      } else if (paymentMode.name === "Bank") {
        paymentPayload.payments.push({
          payment_id: payment_id, // Bank payment method id
          amount: totalAmount, // Amount for bank payment
        });
      } else if (paymentMode.name === "Cash & Bank") {
        paymentPayload.payments.push(
          {
            payment_id: payment_id?.cashId,
            amount: Number(tenderCash),
          },
          {
            payment_id: payment_id?.bankId,
            amount: totalAmount - tenderCash,
          }
        );
      }

      try {
        const paymentResponse = await axiosInstance.post(
          URLS.REFUND_ORDER_PAYMENT,
          paymentPayload
        );
        if (paymentResponse?.data.result.state == "success") {
          setLoading(false);
          navigate(`/OrderCheckout?order_id=${refundOrderId}`);
          dispatch(clearReturnProducts());
          // console.log("Refund order payment successful");
        } else {
          console.error("Error in refund order payment");
          setLoading(false);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsApiInProgress(false);
      }
    }
    // }
  };

  const handleCheckout = (paymentMode) => {
    // if (paymentMode?.name === "Delivery" && (!selectedCustomer?.partnerId || (selectedCustomer?.address_type_id === false && selectedCustomer?.add_field_1 === ""))) {
    // debugger
    // console.log("SELECTED CU", selectedCustomer);

    if (
      paymentMode?.name === "Delivery" &&
      (!selectedCustomer?.partnerId ||
        (!selectedCustomer?.address_type_id &&
          selectedCustomer?.add_field_1 === "" &&
          selectedCustomer?.add_field_2 === "" &&
          selectedCustomer?.add_field_3 === ""))
    ) {
      setIsAddCustomerModalOpen2(true);
      // openAddCustomerModal()
      return;
    }
    if (isApiInProgress) return;
    setIsApiInProgress(true);
    if (returnProducts.length > 0) {
      refundOrderValidate(false, paymentMode);
    } else {
      orderValidate("checkout", selectedCustomer?.partnerId, paymentMode);
    }
  };

  const orderValidate = async (value, partnerID, paymentMode) => {
    // console.log("paymentMode", paymentMode);

    try {
      setLoading(true);
      const lines = localProducts
        ?.filter((item) => item.quantity > 0)
        .map((item) => {
          return [
            0,
            0,
            {
              name: user?.result?.session_name,
              product_id: item.prod_id,
              full_product_name: item.name,
              qty: item.quantity,
              price_unit: item.sale_price,
              price_subtotal: item.sale_price * item.quantity,
              discount: 0,
              tax_ids: [[6, false, []]],
              price_subtotal_incl: item.sale_price * item.quantity,
            },
          ];
        });

      let partner = partnerID ? partnerID : partnerId;
      let date = new Date();

      const padToTwoDigits = (num) => num.toString().padStart(2, "0");
      const year = date.getFullYear();
      const month = padToTwoDigits(date.getMonth() + 1);
      const day = padToTwoDigits(date.getDate());
      const hours = padToTwoDigits(date.getHours());
      const minutes = padToTwoDigits(date.getMinutes());

      let formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
      let payment_id;
      let totalAmount;
      const cashMethod = paymentMethod?.find((item) => item.name === "Cash");
      payment_id = paymentMode.id;

      // if (paymentMode.name == "Bank") {
      //   totalAmount = total;
      // } else {
      //   totalAmount = JSON.parse(tenderCash);
      // }

      if (paymentMode.name === "Delivery") {
        payment_id = paymentMode.id; // Assuming paymentMode.id is the ID for Delivery
        totalAmount = 0; // Set amount to 0 for Delivery
      } else if (paymentMode.name === "Cash & Bank") {
        const cashId = paymentMode.id.cashId;
        const bankId = paymentMode.id.bankId;

        payment_id = cashId; // You can assign cashId or bankId based on your preference
        totalAmount = JSON.parse(tenderCash); // Assuming tenderCash is the total amount paid
      } else {
        payment_id = paymentMode.id; // For other payment methods
        totalAmount =
          paymentMode.name === "Bank" ? total : JSON.parse(tenderCash);
      }

      // let amount_is = paymentMode.name === "Cash & Bank" ? totalAmount : total
      let payment_type;

      if (paymentMode?.name === "Cash") {
        payment_type = "cash";
      } else if (paymentMode?.name === "Bank") {
        payment_type = "bank";
      } else if (paymentMode?.name === "Delivery") {
        payment_type = "delivery";
      } else if (paymentMode?.name === "Cash & Bank") {
        payment_type = "cash_bank";
      } else {
        payment_type = ""; // Default case if none match
      }

      let payloads = {
        data: {
          pos_session_id: user?.result?.session_id,
          user_id: user?.result?.user_id,
          payment_type: payment_type,
          state: "paid",
          amount_paid: paymentMode.name === "Delivery" ? totalAmount : total,
          amount_total: total,
          amount_tax: 0,
          amount_return:
            paymentMode.name === "Cash & Bank"
              ? 0
              : tenderCash
              ? tenderCash - total
              : 0,
          lines: lines,
          to_invoice: false,
          sequence_number: "",
          to_ship: false,
          is_tipped: false,
          tip_amount: 0,
          partner_id: partner && partner != "" ? partner : 0,
          creation_date: formattedDate,
          fiscal_position_id: "",
          pricelist_id: 1,
          statement_ids: [],
        },
      };

      // Add statement IDs based on payment method
      if (paymentMode.name === "Cash & Bank") {
        payloads.data.statement_ids.push(
          [
            0,
            0,
            {
              name: formattedDate,
              payment_method_id: paymentMode.id.cashId, // Use cash ID
              amount: totalAmount,
              payment_status: "",
              ticket: "",
              card_type: "",
              cardholder_name: "",
              transaction_id: "",
            },
          ],
          [
            0,
            0,
            {
              name: formattedDate,
              payment_method_id: paymentMode.id.bankId, // Use bank ID
              amount: tenderCash ? total - tenderCash : 0,
              payment_status: "",
              ticket: "",
              card_type: "",
              cardholder_name: "",
              transaction_id: "",
            },
          ]
        );
      } else {
        payloads.data.statement_ids.push([
          0,
          0,
          {
            name: formattedDate,
            payment_method_id: paymentMode.id, // Use the appropriate payment ID
            amount: totalAmount,
            payment_status: "",
            ticket: "",
            card_type: "",
            cardholder_name: "",
            transaction_id: "",
          },
        ]);
      }
      // console.log("TENDER Cash",tenderCash);
      // console.log("Total ",total);
      // console.log("totalAmount ",totalAmount);

      const response = await axiosInstance.post(
        URLS.CREATE_ORDER_NEW,
        payloads
      );
      if (response?.data.result.status === "Successfully") {
        const order_id = response?.data?.result?.order_id;
        // setIsSubscription(true);
        navigate(`/OrderCheckout?order_id=${order_id}`);
      } else if (
        response?.data.result.status === "Session not in opened state."
      ) {
        setIsSessionLogout(true);
      } else if (response?.data.result.status_code === 400 && response?.data.result.status === "Subscription is Expired...Please Renew.") {
        setIsSubscription(true);
      }
    } catch (err) {
    } finally {
      setLoading(false);
      setIsApiInProgress(false);
    }
  };

  const handleReturnButtonClick = () => {
    const handleClick = () => {
      dispatch(setForRefund(true));
      navigate(`/orders?refundorder`);
    };
    handleClick();
  };

  useEffect(() => {
    const keyMappings = {
      Enter: ["Enter", "إدخال"],
      Escape: ["Escape", "هروب"],
      J: ["J", "ـ"],
      K: ["K", "،"],
      R: ["R", "ٌ"],
    };

    const isKeyMatch = (key, targets) => targets.includes(key);

    const handleGlobalKeyDown = (e) => {
      if (
        e.ctrlKey &&
        isKeyMatch(e.key, keyMappings.Enter) &&
        localProducts.length > 0
      ) {
        e.preventDefault();
        handlePayButtonClick();
        setSelectedIndex(null);
      } else if (isKeyMatch(e.key, keyMappings.Escape)) {
        e.preventDefault();
        if (isAddCustomerModalOpen2 === true) {
          setIsAddCustomerModalOpen2(false);
        } else {
          closePaymentModal();
        }
      } else if (e.shiftKey && isKeyMatch(e.key, keyMappings.R)) {
        e.preventDefault();
        handleReturnButtonClick();
      } else if (e.shiftKey && isKeyMatch(e.key, keyMappings.J)) {
        e.preventDefault();
        openCashInModal();
        closeCashOutModal();
      } else if (e.shiftKey && isKeyMatch(e.key, keyMappings.K)) {
        e.preventDefault();
        openCashOutModal();
        closeCashInModal();
      } else if (
        e.shiftKey &&
        isKeyMatch(e.key, keyMappings.Enter) &&
        localProducts.length > 0 &&
        !isOrderProcessing
      ) {
        e.preventDefault();
        setIsOrderProcessing(true);
        getPaymentMethod();
      }
    };

    window.addEventListener("keydown", handleGlobalKeyDown);
    return () => {
      window.removeEventListener("keydown", handleGlobalKeyDown);
    };
  }, [localProducts, selectedCustomer, openCashInModal, openCashOutModal]);

  const totalQuantity = localProducts?.reduce(
    (sum, item) => sum + item.quantity,
    0
  );

  return (
    <>
      {isPaymentModalOpen && (
        <PaymentModal
          onClose={closePaymentModal}
          grandTotal={totalAmount}
          handleCheckout={handleCheckout}
          setTenderCash={setTenderCash}
        />
      )}
      {isAddCustomerModalOpen && (
        <AddCustomer_Modal
          onClose={() => setIsAddCustomerModalOpen(false)}
          confirm={conformAddCustomerModal}
          showSkipButton={true}
        />
      )}
      {isAddCustomerModalOpen2 && (
        <AddCustomer_Modal
          onClose={() => setIsAddCustomerModalOpen2(false)}
          confirm={confirmAddCustomerModal}
          showSkipButton={false}
        />
      )}
      {isCashInModalOpen && (
        <CashIn_Modal
          onClose={closeCashInModal} // Pass the function to close the modal
          title="Cash In"
          handleSubmit={() => {
            // getData();
            // Handle the submission of cash-in
            closeCashInModal();
            // Add any additional logic for handling the cash-in process
          }}
        />
      )}

      {isCashOutModalOpen && (
        <CashIn_Modal
          onClose={closeCashOutModal} // Pass the function to close the modal
          title="Cash Out"
          handleSubmit={() => {
            // getData();
            // Handle the submission of cash-out
            closeCashOutModal();
          }}
          // cashDifference={dashboardData?.cash_difference}
        />
      )}
      {isSessionLogout && (
        <SessionLogout
        // onClose={() => setIsAddCustomerModalOpen2(false)}
        // confirm={confirmAddCustomerModal}
        // showSkipButton={false}
        />
      )}
      {isSubscription && (
        <SubscriptionExpired
          onClose={closeSubscriptionModal}
        />
      )}
      {loading && <LoadingSpinner />}
      <div className="fixed-bottom bg-white rounded-md border border-solid border-stone-300  shadow-sm px-4 py-2 w-[292%] z-10 ">
        <div className="flex items-center text-xl font-semibold justify-between text-white w-full">
          <div className="flex  flex-row ">
            <div className="flex flex-col items-start w-[370px]">
              <div className="text-black text-sm font-normal">
                {t("Items :")} {itemCount}
              </div>
              <div className="text-xl text-gray-700 justify-center items-center">
                {t("Total :")}{" "}
                {/* <span className="text-2xl ml-1 text-black justify-center items-center">
                {Number.isInteger(totalAmount)
                  ? totalAmount
                  : totalAmount.toFixed(2)}{" "}
                EGP
              </span> */}
                <span className="text-2xl ml-1 text-black justify-center items-center">
                  {/* {i18n.language === "ar" && totalAmount < 0
                    ? `${-totalAmount}-`
                    : Number.isInteger(totalAmount)
                    ? totalAmount
                    : totalAmount.toFixed(2)}{" "}
                  EGP */}
                  {i18n.language === "ar" && totalAmount < 0
                    ? `${-totalAmount.toFixed(2)}-`
                    : totalAmount.toFixed(2)}{" "}
                  EGP
                </span>
              </div>
            </div>
            {/* <div className=" self-end bg-slate-500"> */}
            <div className="  ">
              {/* flex  absolute left-48 */}
              <button
                onClick={handlePayButtonClick}
                // className={`flex flex-col justify-centerr w-[190px] items-center py-5 whitespace-nowrap rounded-xl shadow-sm bg-gradient-to-b from-lime-200 via-lime-500 to-lime-600 text-white max-md:px-5 cursor-pointer ${
                className={`flex flex-col justify-centerr w-[190px] items-center py-5 whitespace-nowrap rounded-xl shadow-sm  bg-[#6BB12C] text-white max-md:px-5 cursor-pointer ${
                  // className={`flex flex-col justify-start px-20 py-4 rounded-[5px] shadow-sm bg-lime-600 text-white ${ bg-[#6BB12C]
                  totalQuantity <= 0 && "opacity-60 cursor-not-allowed"
                }`}
                disabled={totalQuantity <= 0}
              >
                <div>{t("Pay")}</div>
              </button>
            </div>
          </div>
          <div className="flex flex-row gap-5 justify-end">
            <QuickSettleButton
              onClick={getPaymentMethod}
              totalQuantity={totalQuantity}
            />
            <ReturnButton />
            <CustomerButton
              openAddCustomerModal={() => setIsAddCustomerModalOpen2(true)}
              disabled={returnProducts.length > 0}
            />
            {/* <ReturnButton/> */}
            <CashInButton openCashInModal={openCashInModal} />
            <CashOutButton openCashOutModal={openCashOutModal} />
          </div>
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default SessionFooter;
