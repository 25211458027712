import React, { useState, useEffect, version } from "react";
import { Link } from "react-router-dom";
import {
  cash,
  cash_in,
  cash_out,
  open_cash,
  orders,
  total_sale_icon,
  add_customer_icon,
  PlusIcon,
  MinusIcon,
  version_logo,
  info_icon,
} from "../../assets";
// import Dashboard_Table from "../components/Tables/Dashboard_Table";
import { DatePicker } from "antd";
import { openSessionInfo } from "../../Redux/Action/loginAction";
import { useDispatch } from "react-redux";
import axiosInstance from "../../AxiosInstance";
import { URLS } from "../../API/API endpoints";
import { useSelector } from "react-redux";
import CashIn_Modal from "../Modal/CashIn_Modal";
import CashOut_Modal from "../Modal/CashOut_Modal";
import { useTranslation } from "react-i18next";
// import { SalesDashboard } from "../Api";

// const dateFormat = 'DD/MM/YYYY';

function Dashboard() {
  const { t, i18n } = useTranslation();
  // sessionStorage.setItem("user","1")
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.login?.user?.result);
  const [dashboardData, setDashboardData] = useState({});
  const [cashInModal, openCashInModal] = useState(false);
  const [cashOutModal, openCashOutModal] = useState(false);
  const appVersion = process.env.REACT_APP_VERSION;

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      // setLoading(true)
      const response = await axiosInstance.post(URLS.OPEN_SESSION_INFO, {
        user_id: userData?.user_id,
        company_id: userData?.company_id,
      });
      // console.log("response==>", response?.data?.result?.session_status)
      if (response?.data?.result?.status === 200) {
        setDashboardData(response?.data?.result?.response[0]);
      }
    } catch (err) {
      console.error(err);
    } finally {
      // setLoading(false)
    }
  };

  const DetailedCard = ({ title, amount }) => {
    return (
      <div className="flex flex-col items-start py-4 pr-6 pl-6 bg-[#FFFFFF] rounded-lg w-[370px] text-zinc-800">
        <div className="flex flex-row">
          <img src={cash_out} alt="" className="aspect-square w-[45px]" />
          <h2 className="mt-2 text-xl mt-3 ml-4">{title}</h2>
        </div>
        <p className="text-3xl py-6 font-semibold">{amount}</p>
      </div>
    );
  };

  const handleKeyDown = (e) => {
    // Normalize Arabic keyboard input to corresponding English keys
    const key = e.key.toLowerCase();
    const normalizedKey =
      {
        ـ: "j", // Arabic "ض" is similar to English "J"
        "،": "k", // Arabic "ك" is similar to English "K"
        س: "s", // Arabic "س" is similar to English "S"
        ى: "n", // Arabic "ن" is similar to English "N"
      }[key] || key;

    if (e.shiftKey && (normalizedKey === "j" || normalizedKey === "ـ")) {
      e.preventDefault();
      openCashInModal(true);
      openCashOutModal(false);
    } else if (e.shiftKey && (normalizedKey === "k" || normalizedKey === "،")) {
      e.preventDefault();
      e.stopPropagation();
      openCashOutModal(true);
      openCashInModal(false);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  // const handleKeyDown = (e) => {
  //   if (e.shiftKey && e.key === "J") {
  //     e.preventDefault();
  //     openCashInModal(true);
  //     openCashOutModal(false);
  //   } else if (e.shiftKey && e.key === "K") {
  //     e.preventDefault();
  //     e.stopPropagation();
  //     openCashOutModal(true);
  //     openCashInModal(false);
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("keydown", handleKeyDown);
  //   return () => {
  //     window.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, []);

  return (
    <>
      {cashInModal && (
        <CashIn_Modal
          title="Cash In"
          onClose={() => openCashInModal(false)}
          handleSubmit={() => {
            getData();
            openCashInModal(false);
          }}
        />
      )}
      {cashOutModal && (
        <CashIn_Modal
          title="Cash Out"
          onClose={() => openCashOutModal(false)}
          handleSubmit={() => {
            getData();
            openCashOutModal(false);
          }}
          cashDifference={dashboardData?.cash_difference}
        />
      )}

      <div className="px-20 flex gap-4 bg-[#EEEEEE] flex-wrap w-[100%]">
        <div className="flex flex-wrap w-[100%]">
          <h1 className="text-3xl font-semibold mt-7 mb-5 text-slate-900">
            {i18n.language == "ar"
              ? userData?.company_name_ar
              : userData?.company_name}
          </h1>
        </div>

        <div className="flex flex-col items-start py-4 pr-6 pl-6 bg-[#FFFFFF] rounded-[18px] w-[370px] text-zinc-800 relative">
          <div className="flex flex-row justify-between w-full">
            <div className="flex flex-row">
              <img
                src={total_sale_icon}
                alt=""
                className="aspect-square w-[45px]"
              />
              <h2 className="text-xl mt-3 mx-4">
                {t("Total Sales / Earnings")}
              </h2>
              {/* <button className="focus:outline-none">
                <span className="text-blue-500">i</span>
              </button> */}
              <button className="focus:outline-none mt-2">
                <img
                  src={info_icon}
                  alt="info"
                  className="w-4 h-5 object-contain"
                />{" "}
                {/* Replace with your icon */}
              </button>
              <div
                // className="absolute left-full w-60 bg-white text-gray-700 text-sm rounded-md shadow-lg p-2 hidden tooltip"
                className={`absolute ${
                  i18n.language === "ar" ? "right-full" : "left-full"
                } w-60 bg-white text-gray-700 text-sm rounded-md shadow-lg p-2 hidden tooltip`}
              >
                {/* <p>{t("Total Sales:")}</p> */}
                <p>{t("Total Sales = Cash Collection + Bank Collection")}</p>
                <br></br>
                <p>
                  {t("Total Earnings = Total Sales - Total Refunded Amount")}
                </p>
              </div>
            </div>
          </div>
          {/* <p className="text-3xl py-6 font-semibold">
            EGP {dashboardData.total_sales || 0} / EGP{" "}
            {dashboardData.total_earning || 0}
          </p> */}
          <p className="text-3xl py-6 font-semibold">
            {i18n.language === "ar" ? (
              <>
                EGP {dashboardData.total_earning || 0} / EGP{" "}
                {dashboardData.total_sales || 0}
              </>
            ) : (
              <>
                EGP {dashboardData.total_sales || 0} / EGP{" "}
                {dashboardData.total_earning || 0}
              </>
            )}
          </p>
        </div>

        {/* Opening Cash Card */}
        <div className="flex flex-col items-start py-4 pr-6 pl-6 bg-[#FFFFFF] rounded-[18px] w-[370px] text-zinc-800">
          <div className="flex flex-row">
            <img src={open_cash} alt="" className="aspect-square w-[45px]" />
            <h2 className="mt-2 text-xl mt-3 mx-4">{t("Opening Cash")}</h2>
          </div>
          <p className="text-3xl py-6 font-semibold">
            EGP {dashboardData?.opening_cash || 0}
          </p>
        </div>

        <div
          onClick={() => openCashInModal(true)}
          className=" cursor-pointer flex flex-col items-start py-4 pr-6 pl-6 bg-[#FFFFFF] rounded-[18px] w-[370px] text-zinc-800"
        >
          <div className="flex flex-row justify-between w-full">
            <div className="flex flex-row">
              <img src={cash_in} alt="" className="aspect-square w-[45px]" />
              <h2 className="mt-2 text-xl mt-3 mx-4">{t("Cash In")}</h2>
            </div>
            <div className=" bg-[#fff0c7] rounded-3xl mt-2 p-2 ">
              <img src={PlusIcon} alt="" className="  aspect-square w-[20px]" />
            </div>
          </div>
          <p className="text-3xl py-6 font-semibold">
            EGP {dashboardData.cash_in_amt || 0}
          </p>
        </div>

        <div
          onClick={() => openCashOutModal(true)}
          className=" cursor-pointer flex flex-col items-start py-4 pr-6 pl-6 bg-[#FFFFFF] rounded-[18px] w-[370px] text-zinc-800"
        >
          <div className="flex flex-row justify-between w-full">
            <div className="flex flex-row">
              <img src={cash_out} alt="" className="aspect-square w-[45px]" />
              <h2 className="mt-2 text-xl mt-3 mx-4">{t("Cash Out")}</h2>
            </div>
            <div className=" bg-[#fff0c7] rounded-3xl mt-2 p-2 flex items-center ">
              <img
                src={MinusIcon}
                alt=""
                className="  aspect-square w-[20px]"
              />
            </div>
          </div>
          <p className="text-3xl py-6 font-semibold">
            EGP {-dashboardData.cash_out_amt || 0}
          </p>
        </div>

        {/* Number of Orders Card */}
        <div className="flex flex-col items-start py-4 pr-6 pl-6 bg-[#FFFFFF] rounded-[18px] w-[370px] h-[100%] text-zinc-800">
          <div className="flex flex-row">
            <img src={orders} alt="" className="aspect-square w-[45px]" />
            <h2 className="mt-2 text-xl mt-3 mx-4">{t("No. of Orders")}</h2>
          </div>
          <p className="text-3xl py-6 font-semibold">
            {dashboardData?.total_orders || 0}
            {/* <span className="ml-2 text-zinc-500">Products</span> */}
          </p>
        </div>

        {/* Available Cash Card */}
        <div className="">
          <div className="flex flex-row items-start py-2 pr-6 pl-6 bg-[#FFF0C7] rounded-[18px] w-[1145px] h-[100%] text-zinc-800">
            <div className="flex flex-row items-start w-[37%]">
              <img src={cash} alt="" className="aspect-square w-[45px] mt-5" />
              <div className="flex flex-col mt-8">
                <div className="flex flex-row">
                  <h2 className="text-xl mx-4">{t("Available Cash")}</h2>
                  <div className="relative mt-1">
                    <button className="focus:outline-none">
                      <img
                        src={info_icon}
                        alt="info"
                        className="w-4 h-5 object-contain"
                      />{" "}
                    </button>
                    <div
                      className={`absolute ${
                        i18n.language === "ar" ? "right-full" : "left-full"
                      } w-72 bg-white text-gray-700 text-sm rounded-md shadow-lg p-5 hidden tooltip`}
                    >
                      {/* // className="absolute left-full w-72 bg-white text-gray-700 text-sm rounded-md shadow-lg p-5 hidden tooltip"> */}
                      <p>
                        {t(
                          "Available Cash = Cash Collection + Opening Cash + Cash In - Total Refund Amount - Cash Out"
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                {/* <br></br> */}
                <p className="text-3xl mt-4 ms-4 font-semibold">
                  EGP {(-dashboardData?.cash_difference || 0).toFixed(2)}
                  {/* EGP {-dashboardData?.cash_difference || 0} */}
                </p>
              </div>
            </div>
            {/* Small Cards */}
            <div className="flex items-start w-[66%]">
              {/* Cash Collection */}
              <div className="flex flex-col items-start mt-2.5 py-6 px-4 bg-[#FFFAFA] rounded-[18px] w-[354px] text-zinc-800">
                <div className="flex items-center">
                  <img src={cash} alt="" className="" />
                  <p className="text-3xl font-semibold mx-4">
                    EGP {dashboardData.collected_cash || 0}
                  </p>
                </div>
                <h3 className="text-lg ms-16">{t("Cash Collection")}</h3>
              </div>
              {/* ))} */}

              {/* Bank Collection */}
              <div className="flex flex-col mx-4 items-start mt-2.5 py-6 px-4 bg-[#FFFAFA] rounded-[18px] w-[354px] text-zinc-800 ">
                <div className="flex items-center">
                  <img src={total_sale_icon} alt="" className="" />
                  <p className="text-3xl font-semibold mx-4">
                    EGP {dashboardData.bank_collection || 0}
                  </p>
                </div>
                <h3 className="text-lg ms-16">{t("Bank Collection")}</h3>
              </div>
            </div>
          </div>
        </div>

        {/* No. Refunded Orders */}
        <div className="flex flex-col items-start py-4 pr-6 pl-6 bg-[#FFFFFF] rounded-[18px] w-[370px] text-zinc-800">
          <div className="flex flex-row">
            <img
              src={total_sale_icon}
              alt=""
              className="aspect-square w-[45px]"
            />
            <h2 className="mt-2 text-xl mt-3 mx-4">
              {t("No. of Refunded Orders")}
            </h2>
          </div>
          <p className="text-3xl py-6 font-semibold">
            {dashboardData.refunded_order || 0}
          </p>
        </div>

        {/* Total Refunded Amount */}
        <div className="flex flex-col items-start py-4 pr-6 pl-6 bg-[#FFFFFF] rounded-[18px] w-[370px] text-zinc-800">
          <div className="flex flex-row">
            <img src={cash_out} alt="" className="aspect-square w-[45px]" />
            <h2 className="mt-2 text-xl mt-3 mx-4">
              {t("Total Refunded Amount")}
            </h2>
          </div>
          <p className="text-3xl py-6 font-semibold">
            EGP {dashboardData?.refunded_amt || 0}
          </p>
        </div>
        <div className="fixed bottom-0 right-2 p-4  text-gray-400 text-sm text-center">
          <img
            src={version_logo}
            alt="version_logo"
            className="h-[80%] w-[80%]"
          />
          {t("v")} {appVersion}
        </div>
        {/* <DetailedCard title="Total Orders" amount={dashboardData?.total_orders} /> */}
        {/* <Dashboard_Table></Dashboard_Table> */}
      </div>
      <div></div>
    </>
  );
}

export default Dashboard;
