import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchOrderReceipt } from "../../../Redux/Action/orderReceiptAction";
import OrderReceipt from "./OrderReceipt";
import { plus_icon, send_icon } from "../../../assets/";
import { clearProducts } from "../../../Redux/Action/productsAction";
import { clearCustomer } from "../../../Redux/Action/getcustomerAction";
import axiosInstance from "../../../AxiosInstance";
import { URLS } from "../../../API/API endpoints";
import { useTranslation } from "react-i18next";
import OrderReceiptPDF from "./OrderReceiptPDF";
import { clearReturnProducts } from "../../../Redux/Action/returnproductAction";
import { clearPaymentMethod } from "../../../Redux/Action/paymentAction";

const SESSION_STORAGE_KEY = "products";
const RETURN_PRODUCTS = "returnProductReducer";

function ReceiptOption({ option, icon }) {
  const { t, i18n } = useTranslation();
  return (
    <div className="flex gap-5 py-1.5 pr-1 pl-6 mt-8 text-base text-black-400 bg-white rounded-lg max-md:flex-wrap max-md:pl-5 max-md:max-w-full">
      <input
        type="text"
        placeholder={t(option)}
        className="flex-auto my-auto outline-none"
      />
      <img
        src={send_icon}
        alt=""
        className={`shrink-0 rounded-md aspect-[1.59] w-[93px] cursor-pointer transition-transform transform ${
          i18n.language == "ar" ? "scale-x-[-1]" : ""
        }`}
        onClick={() => console.log("Send receipt")}
      />
    </div>
  );
}

function OrderCheckout() {
  const { t, i18n } = useTranslation();
  const [receiptData, setReceiptData] = useState();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state?.login);
  const store_id = user?.store_id;
  const navigate = useNavigate();
  // const { order_id } = useParams();
  const order_id = new URLSearchParams(window.location.search).get("order_id");
  const userData = useSelector((state) => state?.login?.user?.result);

  // Auto print window

  useEffect(() => {
    if (receiptData?.order[0]?.order_no) {
      handlePrintReceipt();
    }
  }, [receiptData?.order[0]?.order_no]);

  useEffect(() => {
    const getReceiptData = async () => {
      try {
        if (order_id) {
          //   const data = await dispatch(fetchOrderReceipt(Number(order_id)));
          const response = await axiosInstance.post(
            URLS.RECEIPT_ORDER_DETAILS,
            {
              lang: i18n.language === "ar" ? "ar_001" : "en_US",
              order_id: Number(order_id),
            }
          );
          if (response?.data?.result?.state == "success") {
            // console.log("response.data.Order", response?.data?.result);
            setReceiptData(response?.data?.result);
            dispatch(clearProducts());
            dispatch(clearCustomer());
            dispatch(clearPaymentMethod());
          }
        } else {
          console.error("No order_id found");
        }
      } catch (error) {
        console.error("Error fetching receipt data:", error);
      }
    };

    getReceiptData();
  }, [order_id, dispatch]);

  const receiptOptions = [{ option: "WhatsApp number", icon: "" }];

  // const handlePrintReceipt = () => {
  //   const iframe = document.createElement("iframe");
  //   iframe.style.position = "absolute";
  //   iframe.style.width = "0";
  //   iframe.style.height = "0";
  //   iframe.style.border = "none";

  //   document.body.appendChild(iframe);

  //   const doc = iframe.contentWindow.document;
  //   doc.open();
  //   doc.write(OrderReceiptPDF(receiptData, t, i18n.language, userData));
  //   doc.close();

  //   iframe.contentWindow.focus();
  //   // iframe.contentWindow.print();

  //   setTimeout(() => {
  //     iframe.contentWindow.print();
  //     document.body.removeChild(iframe);
  //   }, 1000);
  // };

  const handlePrintReceipt = () => {
    const printWindow = window.open("", "_blank");
  
    if (printWindow) {
      printWindow.document.write(`
        <html>
          <head>
            <title>Receipt</title>
            <style>
              
              body { font-family: Arial, sans-serif; }
              h1, h2, h3 { text-align: center; }
              
            </style>
          </head>
          <body>
            ${OrderReceiptPDF(receiptData, t, i18n.language, userData)}
          </body>
        </html>
      `);
      printWindow.document.close();
      printWindow.focus();
  
      // Use a timeout to ensure the document is fully loaded before printing
      setTimeout(() => {
        printWindow.print();
        printWindow.close();
      }, 1000);
    } else {
      console.error("Failed to open print window. Please check your popup blocker settings.");
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Normalize key input for consistent handling
      const normalizedKey =
        {
          " ": "Space", // Space key normalization
          Shift: "Shift", // Shift key normalization
          p: "ح", // Ctrl+P key normalization
        }[event.key] || event.key; // Fallback to the original key if no mapping exists

      if (event.ctrlKey && normalizedKey === "ح") {
        event.preventDefault();
        handlePrintReceipt();
      } else if (normalizedKey === "Space") {
        event.preventDefault();
        handleNewOrderClick();
      } else if (normalizedKey === "Shift") {
        event.preventDefault();
        handleFinishClick();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [receiptData]);

  const handleNewOrderClick = () => {
    localStorage.removeItem(SESSION_STORAGE_KEY);
    dispatch(clearReturnProducts());
    navigate("/session");
  };

  const handleFinishClick = () => {
    localStorage.removeItem(SESSION_STORAGE_KEY);
    dispatch(clearReturnProducts());
    navigate("/dashboard");
  };

  return (
    <>
      <div className="flex">
        <div className="flex justify-center max-md:flex-col max-md:gap-0 w-[70%] ">
          <div className="mt-28 w-[60%]">
            <div className="flex flex-row justify-between ms-5 me-5 ">
              {receiptData?.order[0].customer && (
                <div className="text-lg">
                  {t("Customer Name")} <br></br>
                  <span className="text-black font-semibold text-xl">
                    {isNaN(parseFloat(receiptData?.order[0].customer)) &&
                    !/^\d{3}[-.]?\d{3}[-.]?\d{4}$/.test(
                      receiptData?.order[0].customer
                    )
                      ? receiptData?.order[0].customer
                      : "--"}
                  </span>
                </div>
              )}
              {receiptData?.order[0].customer_mobile && (
                <div className="text-lg">
                  {t("Phone Number")} <br></br>
                  <span className="text-black font-semibold text-xl">
                    {receiptData?.order[0].customer_mobile}
                  </span>
                </div>
              )}
            </div>
            <section className="flex flex-col px-5  text-xl max-md:mt-10 max-md:max-w-full">
              <h2 className="mt-24 text-2xl font-bold tracking-wide leading-none text-zinc-800 max-md:mt-10 max-md:max-w-full">
                <span className="text-zinc-800">
                  {t("How would you like to receive the receipt?")}
                </span>
              </h2>
              <button
                className="justify-center items-center px-16 py-4 mt-8 font-semibold tracking-wide leading-10 text-center text-white bg-[#FABF01] border border-solid border-stone-300  capitalize rounded-lg max-md:px-5 max-md:max-w-full"
                onClick={handlePrintReceipt}
              >
                {t("Print Receipt")}
              </button>
              <div className="flex flex-row w-full gap-8 justify-between mt-4">
                <button
                  className="justify-center items-center w-[50%] py-4 mt-8 font-semibold tracking-wide leading-10 text-center text-[#1b4597] bg-white border border-[#1b4597] border-2 border-solid capitalize rounded-lg max-md:px-5 max-md:max-w-full"
                  onClick={handleFinishClick}
                >
                  {t("Finish")}
                </button>

                <button
                  className="justify-center items-center w-[50%]  py-4 mt-8 font-semibold tracking-wide leading-10 text-center text-white bg-[#1b4597] border  capitalize rounded-lg max-md:px-5 max-md:max-w-full"
                  onClick={handleNewOrderClick}
                >
                  {t("New Order")}
                </button>
              </div>

            </section>
          </div>
          {/* <NewOrderButton /> */}
        </div>
        {receiptData && <OrderReceipt receiptData={receiptData} />}
      </div>
    </>
  );
}

export default OrderCheckout;
