import React, { useEffect, useRef, useState } from "react";
import { CashInOut } from "../../Api";
import { URLS } from "../../API/API endpoints";
import axiosInstance from "../../AxiosInstance";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const LoadingSpinner = () => {
  return (
    <div
      style={{ zIndex: 100 }}
      className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50"
    >
      <div className="spinner">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};


function Unpaid_Modal({ onClose, handleSubmit, orderDetails, onPaymentSuccess }) {
  const { t, i18n } = useTranslation();
  const { user } = useSelector((state) => state?.login);
  const [paymentMethod , setPaymentMethod] = useState([])
  const [loading, setLoading] = useState(false);


  // console.log(orderDetails,"orderDetails");

  const getPaymentMethod = async (userID) => {
    try {
      const response = await axiosInstance.post(URLS.PAYMENT_METHODS, {
        user_id: userID,
      });
      if (response?.data.result.data != null) {
        const methods = response.data.result.data.map((method) => ({
          name: method.name,
          id: method.id,
        }));
        setPaymentMethod([...methods]);
      }
    } catch (err) {
      console.error(err);
    } finally {
    }
  };

  useEffect(() => {
    getPaymentMethod(user?.result?.user_id);
  }, [user?.result?.user_id]);

  
  

  const handleCollect = async () => {
    // console.log("paymentMethod", paymentMethod)
    setLoading(true)

    const cashPaymentMethod = paymentMethod.find(method => method.name === "Cash");

    if (!cashPaymentMethod) {
      console.error("Cash payment method not found");
      setLoading(false)
      return;

    }

    const payload = {
      order_id : orderDetails?.id,
      amount : orderDetails?.order_value,
      payment_name : "Delivery",
      payment_method_id :  cashPaymentMethod.id 
    }

    MakeOrderPayment(payload)
  }

  const MakeOrderPayment = async (payload) => {
    try {
      // setLoading(true)
      const response = await axiosInstance.post(URLS.COLLECT_PAYMENT,payload, {
      });
      // console.log(response);

      if (response?.data?.result?.status === "Payment Successfully") {
        // console.log("badc");
        onPaymentSuccess();
        
        onClose();
        setLoading(false)
        // setDashboardData(response?.data?.result?.response[0]);
      }
    } catch (err) {
      console.error(err);
    } finally {
      // setLoading(false)
    }
  };
    
  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      onClose();
    }

    if (e.key === "Enter") {
      console.log("RECED");
      
      handleCollect();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0'); 
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const year = date.getFullYear(); 
    return `${day}/${month}/${year}`; 
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 backdrop-blur-md drop-shadow-sm">
       {loading && <LoadingSpinner />}
      <div className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-40"></div>
      <section
        className="relative flex flex-col px-12 py-12 text-lg font-semibold leading-6 bg-white rounded-[25px] text-zinc-800 max-md:px-5 z-50"
        style={{ width: "600px" }}
      >
        <header className="flex gap-5 text-3xl max-md:flex-wrap max-md:max-w-full">
          <h1 className="flex-auto my-auto">{t("Collect Amount")}</h1>
          <button
            type="button"
            onClick={onClose}
            className="absolute top-4 right-3 text-zinc-800"
          >
             <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </header>
        <main className="mt-4">
          <div className="border-b pb-4 mb-4">
            <h2 className="text-xl font-bold">{t("Order Details")}</h2>
          </div>
          <div className="grid grid-cols-1 gap-4">
            <div className="flex items-center">
              <span className="font-bold">{t("Receipt No:")}</span>
              <span className="ms-2 font-normal">{orderDetails?.receipt_no}</span>
            </div>
            <div className="flex items-center">
              <span className="font-bold">{t("Date")}:</span>
              <span className="ms-2 font-normal">{formatDate(orderDetails?.date_order)}</span>
              </div>
            <div className="flex items-center">
              <span className="font-bold">{t("Delivery Time:")}</span>
              <span className="ms-2 font-normal">{orderDetails?.delivery_time}</span>
            </div>
            {orderDetails?.customer_name && (
              <div className="flex items-center">
                <span className="font-bold">{t("Customer Name:")}</span>
                <span className="ms-2 font-normal">{orderDetails?.customer_name}</span>
              </div>
            )}
            {orderDetails?.customer_phone && (
              <div className="flex items-center">
                <span className="font-bold">{t("Customer Phone")}</span>
                <span className="ms-2 font-normal">{orderDetails?.customer_phone}</span>
              </div>
            )}
            <div className="flex items-center">
              <span className="font-bold">{t("Items")}:</span>
              <span className="ms-2 font-normal">{orderDetails?.items}</span>
            </div>
            <div className="flex items-center">
              <span className="font-bold">{t("Order Value:")}</span>
              <span className="ms-2 font-normal">{orderDetails?.order_value} {orderDetails?.currency}</span>
            </div>
            
          </div>
        </main>
        <footer>
          <button
            className="justify-center items-center px-16 py-3.5 mt-16 text-xl text-center text-white capitalize whitespace-nowrap bg-[#7EC242] rounded-lg max-md:px-5 max-md:mt-10 max-md:max-w-full"
            style={{ width: "500px" }}
            onClick={handleCollect}
          >
            {t("Collect")}
          </button>
        </footer>
      </section>
    </div>
  );
}

export default Unpaid_Modal;
