// actions/paymentActions.js

export const SET_SELECTED_PAYMENT_METHOD = "SET_SELECTED_PAYMENT_METHOD";
export const CLEAR_PAYMENT_METHOD = "CLEAR_PAYMENT_METHOD";

export const storeSelectedPaymentMethod = (method) => ({
  type: SET_SELECTED_PAYMENT_METHOD,
  payload: method,
});

export const clearPaymentMethod = () => ({
    type: CLEAR_PAYMENT_METHOD,
  });